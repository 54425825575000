module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kimgadget キムガジェット","short_name":"Kimgadget","start_url":"/","background_color":"#ffffff","theme_color":"#477294","display":"standalone","icon":"src/images/scye.jpeg"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-8LH4ZBX5LM"],"pluginConfig":{"head":true}},
    }]
